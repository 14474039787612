$(document).ready(function () {
    $('#data').datepicker({language: 'pt-BR'});

    $('.icheck').iCheck({
        checkboxClass: 'icheckbox_flat-green',
        radioClass: 'iradio_flat-green'
    });

    $('.summernote').summernote();
    $("#datatable").dataTable();
    $('.dataTables_filter input').addClass('form-control').attr('placeholder', 'Search');
    $('.dataTables_length select').addClass('form-control');
    $('.dataTables_filter input').addClass('form-control').attr('placeholder', 'Search');
    $('.dataTables_length select').addClass('form-control');
    function reloadActions() {
        $("#position").sortable({
            cursor: "move",
            stop: function (event, ui) {
                var ids = $("#position").sortable("toArray");
                var url = $("#position").data('route');
                $.post(url, {item: ids}, function (data) {
                    $.gritter.add({
                        title: 'Sucesso!',
                        text: 'Posição modificada!',
                        class_name: 'primary'
                    });
                });
            }
        });
        $("#position").disableSelection();
    }
    $(function () {
        Dropzone.autoDiscover = false;
        $(".dropzone").dropzone({
            accept: function (file, done) {
                done();
            },
            maxFilesize: 2000,
            complete: function (file) {
                if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {
                    $('.dz-preview').each(function () {
                        $(this).fadeOut(1000);
                    });
                    window.location.reload();
                }
            },
            sending: function (file, xhr, formData) {
                if (xhr.readyState === 4) {
                    //console.log(xhr.response)
                }
            },
            totaluploadprogress: function () {
            }
        });
    });
    $('#btn-submit-remove').hide();
    $('#btn-submit-remove').on('click', function () {
        if ($('input:checked').length >= 1) {
            $('#form-remove').submit();
        }
    });
    $('.click-remove').on('click', function () {
        var id = $(this).attr('id');
        $('#check_' + id).trigger('click');
        $('#' + id).toggleClass('btn-danger');
        if ($('input:checked').length >= 1) {
            $('#btn-submit-remove').show();
        } else {
            $('#btn-submit-remove').hide();
        }
    });
    $('.btn-sel-tudo').on('click', function () {
        $('.click-remove').each(function () {
            var id = $(this).attr('id');
            $('#' + id).addClass('btn-danger');
            $('#check_' + id).attr('checked', 'checked');
        });
        $('.btn-sel-tudo').hide();
        $('.btn-sel-nada').removeClass('hide').show();

        if ($('input:checked').length >= 1) {
            $('#btn-submit-remove').show();
        } else {
            $('#btn-submit-remove').hide();
        }
        $('#btn-submit-remove').show();
    });

    $('.btn-sel-nada').on('click', function () {
        $('.click-remove').each(function () {
            var id = $(this).attr('id');
            $('#' + id).removeClass('btn-danger');
            $('#check_' + id).removeAttr('checked');
        });
        $('.btn-sel-tudo').show();
        $('.btn-sel-nada').hide();

        if ($('input:checked').length >= 1) {
            $('#btn-submit-remove').show();
        } else {
            $('#btn-submit-remove').hide();
        }
    });
    $(function () {
        reloadActions();
    });
});